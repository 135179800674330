*{
  margin: 0;
  padding: 0;
}

/* font-family: 'Crimson Pro', serif;
font-family: 'DM Sans', sans-serif; */


#navigation{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  height: 10vh;
  background-color: #D71E3C;
}

.images{
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  float: left;
}
@media screen and (max-width:610px){
  .images{
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    text-align: center;
  }
}
.connect{
  display: flex;
  float: left;
}

.app{
  width: 100%;
}
.sidebar{
  background-color:#EBF0F2;
  width:25%;
  max-height:100%;
  height:100%;
  display:block;
  box-shadow: 1px 5px lightgrey;
  position:fixed;
}


.sidebar-list{
  overflow-y: scroll;
  width: 100%;
  height: 58%;
}

.selected-list{
  list-style-type: none;
  display: flex;
  flex-wrap:nowrap;
  height: 12vh;
  margin-left: auto;
  margin-right: auto;
  align-items:center;
  box-shadow: 1px 1px lightgrey;

}

.home{
  padding: 0;
  margin:0;
  float: left;
  margin-left: 25%;
}

@media screen and (max-width:1000px){
  .sidebar{
    display: none;
  }
  .home{
    width: 100%;
    margin-left: 0;
  }
}
